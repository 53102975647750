const places = [
    {
        name: "Alamelu Mangapuram",
        image: "https://tirumalatirupatiyatra.in/wp-content/uploads/2017/09/Sri-Padmavathi-Ammavari-TempleTiruchanur.jpg"
    },
    {
        name: "Arunachalam",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRN3ETktSmeo40MygoADOaYlTRbHDnNh-7low&s"
    },
    {
        name: "Chidambaram Temple",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Nataraja_Temple_Chidambaram.jpg/800px-Nataraja_Temple_Chidambaram.jpg"
    },
    {
        name: "Golden Temple",
        image: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/02/3c/e2/2c/golden-temple-3.jpg?w=900&h=500&s=1"
    },
    {
        name: "Kalyana Venkateswara Swamy",
        image: "https://content3.jdmagicbox.com/comp/tirupati/dc/9999px877.x877.100124115841.q8p2dc/catalogue/sri-kalyana-venkateswara-swamy-temple-tirupati-ho-tirupati-temples-o36jw64.jpg"
    },
    {
        name: "Kanipakam Temple",
        image: "https://www.holidify.com/images/cmsuploads/compressed/VinayakaTemple_20200508120615.jpg"
    },
    {
        name: "Kanchi Kamakshi",
        image: "https://templeinkarnataka.com/wp-content/uploads/2024/08/kanchi-kamakshi-temple.jpg"
    },
    {
        name: "Madurai Meenakshi Temple",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Meenakshi_Temple_Tower.JPG/800px-Meenakshi_Temple_Tower.JPG"
    },
    {
        name: "Murudeshwar Temple",
        image: "https://upload.wikimedia.org/wikipedia/commons/6/6b/Murudeshwara_Gopuram.jpg"
    },
    {
        name: "Rameshwaram Temple",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/Ramanathaswamy_temple_Rameswaram.jpg/800px-Ramanathaswamy_temple_Rameswaram.jpg"
    },
    {
        name: "Sri Kalahasti Temple",
        image: "https://cdn.abhibus.com/2024/08/Srikalahasti-Temple.jpg"
    },
    {
        name: "Sripuram Temple",
        image: "https://www.sripuram.org/wp-content/uploads/2020/08/Sripuram.jpg"
    },
    {
        name: "Srirangam Ranganathaswamy Temple",
        image: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Srirangam_Ranganathaswamy_Temple.jpg/800px-Srirangam_Ranganathaswamy_Temple.jpg"
    },
    {
        name: "Thiruvannamalai Temple",
        image: "https://upload.wikimedia.org/wikipedia/commons/8/8b/Tiruvannamalai_Arunachaleswara_Temple.jpg"
    },
    {
        name: "Tirumala Temple",
        image: "https://static.toiimg.com/photo/114652427.cms"
    }
];

export default places;