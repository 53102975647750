import { useNavigate } from "react-router-dom";
import places from "./Places";

function PlacesList() {
  const navigate = useNavigate();

  return (
    <section className="travel-places-container">
      <div className="container">
        <div className="plan-container__title">
          <h3>Must Visit Temples</h3>
          <h2>Quick & easy car rental</h2>
        </div>
        <div className="travel-places">
          {places.slice(0, 8).map((place, id) => (  // Show only 6 places
            <div key={id} className="travel-places__box">
              <div className="travel-places__box__img">
                <img src={place.image} alt={place.name} />
              </div>
              <div className="travel-places__box__descr">
                <h3>{place.name}</h3>
              </div>
            </div>
          ))}
        </div>
        <div className="explore-button-container">
          <button className="explore-btn" onClick={() => navigate("/places")}>
            View More
          </button>
        </div>
      </div>
    </section>
  );
}

export default PlacesList;