import places from "../components/Places";
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";

function PlacesPage() {
  return (
    <>
      <section className="places-page">
        {/* Header Section */}
        <HeroPages name="Must Visit Temples" />

        {/* Places Section */}
        <section className="travel-places-container">
          <div className="container">
            <div className="plan-container__title">
              <h3>Must Visit Temples</h3>
              <h2>Explore the Divine Destinations</h2>
            </div>

            <div className="travel-places">
              {places.map((place, id) => ( // Show all places
                <div key={id} className="travel-places__box">
                  <div className="travel-places__box__img">
                    <img src={place.image} alt={place.name} />
                  </div>
                  <div className="travel-places__box__descr">
                    <h3>{place.name}</h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Footer Section */}
        <Footer />
      </section>
    </>
  );
}

export default PlacesPage;
